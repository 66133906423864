import React from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import special from "../../assets/images/special.png";
import { useEffect } from 'react';
import { navigate } from "gatsby";

function CheckOutSuccess() {
    const { width, height } = useWindowSize()
    useEffect(() => {
        setTimeout(() => {
            navigate("/");
        }, 10000);
        return () => {

        }
    }, [])
    return (
        <>
            <Confetti
                width={width}
                height={height}
            />

            <div className="complete-div">
                <div className="complete">
                    <div className="img">
                        <img src={special} alt="" />
                    </div>
                    <div className="info">
                        <div className="title">Your Payment is complete</div>
                        <div className="msg">Please kindly check your email.</div>

                        <span className="small">You will be redirected on homepage in 10 second.</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CheckOutSuccess

import React from 'react'
import SEO from '../../components/seo';
import CheckOutSuccess from '../../Views/CheckOut/CheckOutSuccess';

function success() {
    return (
        <div>
            <SEO title="success" />
            <CheckOutSuccess />
        </div>
    )
}

export default success
